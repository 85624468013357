.textInfo {
    color: white;
    font-family: "Century Gothic", 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    letter-spacing: 2px;
    font-size: 14px;
}

.lineBreak {
    width: 70%;
    border-bottom: 2px solid white;
}