.MainBanner {
    height: 100vh;
    width: 100vh;
    z-index: 0;
}

.backgroundImage{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    max-width: 100%;
    height: auto;
    min-height: 100%;
    min-width: 100%;
}

.mySlides {
    display: none;
}

/* Fading animation */
.fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 9s;
    animation-name: fade;
    animation-duration: 9s;
    animation-fill-mode: forwards;
  }
  
  @-webkit-keyframes fade {
    0%,100% {opacity: 0} 
    50% {opacity: 1}
  }
  
  @keyframes fade {
    0%,100% {opacity: 0} 
    50% {opacity: 1}
  }