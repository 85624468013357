.LogoComponent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
 
.Name {
    font-family: futura-pt, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: white;
    letter-spacing: 10px;
}

.PersonalTag {
    font-family: "Century Gothic", 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #FFFFFF;
    letter-spacing: 7px;
    font-size: 20px;
}

.Logo {
    margin: 0 auto;
    /* padding-left: 2vh; */
    width: 500px;
    height: auto;
}

.BookingComp {
    font-family: futura-pt, sans-serif;
    letter-spacing: 3px;
    font-size: 25px;
    padding: 10vh 0;
    color: white;
}

.BookingButton {
    font-family: futura-pt, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: white;
    letter-spacing: 6px;
    text-decoration: none !important;
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
    transition: all 1s;
}

.BookingButton:hover {
    color: rgb(219, 117, 134);
    -webkit-filter: grayscale(0);
    filter: none;
    cursor: pointer;
}

.SocialIcons {
    margin: 10px 4px;
    width: 40px;
    height: auto;
    filter: rgb(219, 117, 134); /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
    transition: all 1s;
}

.SocialIcons:hover {
    background-color: rgb(219, 117, 134);
    border-radius: 45%;
    -webkit-filter: grayscale(0);
    filter: none;
}

@media (max-width: 499px) {
    .Logo {
        width: 300px;
        padding-bottom: 10px;
    }
    .Name {
        font-size: 40px;
    }
    .PersonalTag {
        font-size: 20px;
        letter-spacing: 2px;
    }
    .BookingButton {
        letter-spacing: 3px;
    }

    .BookingComp {
        font-size: 15px;
    }

    .badAlignFix {
        padding-right: 0px;
    }
}