.AboutComponent {
    /* width: auto; */
    /* background-color: rgba(255,255,255,0.2); */
    background-color: rgba(0,0,0,0.4);
    border-radius: 20px 20px 0 0;
    position: relative;
}

.AboutInfo,
.ResidencyInfo {
    font-family: futura-pt, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 25px;
    color: white;
    position: relative;
    padding: 50px;
}

.ResidencyInfo {
    padding: 0px;
    margin: 0px;
}

.CurrentResidency {
    padding: 50px 0px;
    text-align: center;
    color: white;;
}

@media (min-width: 1050px) {
    .AboutInfo {
        padding: 50px 200px;
    }
}

@media (max-width: 499px) {
    .AboutInfo {
        font-size: 20px;
    }
}