.MusicEventsComponent {
    background-color: rgba(0,0,0,0.4);
    /* border-radius: 20px 20px 0 0; */
    position: relative;
    left: 0;
    right: 0;
    z-index: 1;
}

.PastEventName {
    margin: 10vh auto;
    text-align: center;
    color: white;
}