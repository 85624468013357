.buttonToTop {
    position: fixed;
    right: 20px;
    bottom: 60px;
}

i {
    position: relative;
    border: solid white;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 3px;
    height: 20px;
    width: 20px;
    z-index: 3;
}

i:hover {
    cursor: pointer;
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}