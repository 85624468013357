.FooterComponent {
    margin-top: 100px;
    padding-bottom: 50px;
    position: relative;
    background-color: rgba(0,0,0,0.8);
}

.FooterButton {
    font-family: "Century Gothic", 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(255, 255, 255);
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin: 4px 2px;
    cursor: pointer;
    text-decoration: none !important;

}